class Hero {
    constructor() {
        this.heroEl = document.querySelector('[data-nelement="hero-shape"]');
        this.lFollowX = 0;
        this.lFollowY = 0;
        this.x = 0;
        this.y = 0;
        this.friction = 1 / 30;
        if (this.heroEl) {
            this.init();
        }
    }

    init() {
        document.addEventListener('mousemove', (e) => {
            const lMouseX = Math.max(-100, Math.min(100, window.innerWidth / 2 - e.clientX));
            const lMouseY = Math.max(-100, Math.min(100, window.innerHeight / 2 - e.clientY));

            this.lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
            this.lFollowY = (10 * lMouseY) / 100;
        });

        this.moveBackground();
    }

    moveBackground() {
        this.x += (this.lFollowX - this.x) * this.friction;
        this.y += (this.lFollowY - this.y) * this.friction;

        const translate = 'translate(' + this.x + 'px, ' + this.y + 'px) scale(1.1)';

        this.heroEl.style.transform = translate;
        this.heroEl.style.webkitTransform = translate;

        window.requestAnimationFrame(this.moveBackground.bind(this));
    }
}

export default new Hero();
